.link {
	background-color: rgb(250, 229, 210);
	border: solid 2px black;
	border-radius: 10%;
	margin: 8px;
}

.head {
	display: flex;
	text-align: center;
	max-height: fit-content;
	background-color: rgb(213, 194, 179);

}

.logo {
	margin-top: 3rem;
}

.main {
	position: relative;
}

.pictures {
	margin: 4px;
	border-radius: 15%;
}

.prayer {
	display: flex;
	justify-content: flex-end;
}


h1 {
	font-size: 50px;
	padding: 20px;
}

p {
	font-size: 15px;


}

.bi {
	font-size: 20px;
	color: black;


}

.family {
	padding-top: 15px;
	margin-top: 20px;
}

.map {
	border-radius: 5px;

}

.contact {
	display: flex;
	justify-content: space-evenly;
}

.bottompix {
	display: flex;
	justify-content: space-around;
}

.footer-site {
	background-color: rgb(213, 194, 179);
	text-align: center;
}


.donate {
	color: white;
	background-color: green;
	width: 150px;
	height: 50px;
	margin-top: 65px;
}

.service {
	margin-top: 4rem;
}



